import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import Footer from "../components/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { MdCall, MdEmail } from "react-icons/md";

import Noc from "../makkaanpages/images/Avenue 18 1st Web Image.webp";
import Amenities from "../makkaanpages/images/Avenue 18 2nd Web Image.webp";
import Const1 from "../makkaanpages/images/avenue_development.png";
import Const2 from "../makkaanpages/images/ayubia5.webp";

import floor from "../makkaanpages/images/avenue18floor.png";
import payment from "../makkaanpages/images/avenue18payment.png";
import developerlogo from "../makkaanpages/images/avenue logo grey.png";
import makkaanLogo from "../makkaanpages/images/makkaanlogo.png";
import paymentplan from "../makkaanpages/pdf/AVENUE 18 PAYMENT PLAN final.pdf";
import floorplan from "../makkaanpages/pdf/AVENUE 18 FLOOR PLAN FINAL.pdf";
import Slider from "react-slick";

import "./CustomArrow.css";
import "../App.css";
import api_url from "../ApiUrl";
import axios from "axios";

import { SampleNextArrow, SamplePrevArrow } from "./CustomArrow";

function Avenue18() {
  const [modalImage, setModalImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedd, setIsExpandedd] = useState(false);
  const fullText = `Makkaan.com has launched Avenue 18, a premier commercial
  project redefining investment opportunities in DHA Phase 1, Islamabad. Perfectly positioned on the DHA Phase 4 main
  link road, this architectural marvel is located in the heart of a rapidly expanding commercial hub, making it an
  ideal destination for businesses and investors. With its three open sides, 7-story design, and a stunning 20-ft
  atrium, Avenue 18 stands as a beacon of modern architecture and functionality. Offering premium spaces
  for shops, food courts, and offices, the project boasts a grand entrance, high-speed elevators, and state-of-the-art
  facilities to meet the demands of dynamic business environments. Avenue 18 by Makkaan.com, ensures a
  future-ready investment with guaranteed on-time delivery in 3 years and a flexible 4-year payment plan.`;

  const shortenedText = `${fullText.slice(0, 300)}...`; // Limit to 150 characters

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const stages = [{ id: "media2", label: "December 2024", media: Const1 }];

  const [activeStage, setActiveStage] = useState(stages[0].id);

  const handleNext = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const nextIndex = (currentIndex + 1) % stages.length;
    setActiveStage(stages[nextIndex].id);
  };

  const handlePrev = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const prevIndex = (currentIndex - 1 + stages.length) % stages.length;
    setActiveStage(stages[prevIndex].id);
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Tab bar Functionally
  const navRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [navOffset, setNavOffset] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (navRef.current) {
      setNavOffset(navRef.current.offsetTop);
    }

    const handleScroll = () => {
      if (window.pageYOffset >= navOffset) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navOffset]);

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const submit = () => {
    console.log("Submit button clicked");
    const fullName = document.getElementById("fullName");
    const phone = document.getElementById("phone");
    const city = document.getElementById("city");
    const email = "kirmani481@gmail.com";

    const yourMessage = document.getElementById("yourMessage");

    if (!fullName.value.trim()) {
      toast.error("Must enter name");
    } else if (!phone.value.trim()) {
      toast.error("Must enter phone");
    } else if (!yourMessage.value.trim()) {
      toast.error("Must enter message");
    } else {
      const params = {
        fullName: fullName.value.trim(),
        phone: phone.value.trim(),
        city: city.value.trim(),
        email: email,
        yourMessage: yourMessage.value.trim(),
      };
      console.log(params, "params data");

      axios
        .post(`${api_url}contact/create`, params)
        .then((res) => {
          if (res.data.status === "ok") {
            toast.success("Your message was submitted successfully!");

            // Optionally reload or navigate after a delay
            setTimeout(() => {
              // Example: Navigate to a thank-you page
              // window.location.href = "/thank-you";

              // Reload the current page
              window.location.reload();
            }, 3000);
          } else {
            toast.error(res.data.message || "Error submitting your message");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("An unexpected error occurred. Please try again later.");
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>Avenue 18 Islamabad - DHA 1 | Makkaan</title>
        <meta
          name="description"
          content="
Explore Avenue 18 Islamabad – premium commercial spaces with modern amenities and prime location. "
        />
      </Helmet>
      <div className="position-relative view_property">
        <ToastContainer />
        <div
          className=" position-fixed w-100"
          style={{ zIndex: "999999", marginTop: "-8em" }}
        >
          <TopNav />
          <BottomNav />
        </div>
        <div style={{ marginTop: "8em", zIndex: "0" }}>
          <div className="container mt-3 mb-3">
            <div className="row">
              <div className="col-sm-8">
                <Slider {...settings}>
                  <div>
                    <img
                      loading="lazy"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Noc}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div>
                    <img
                      loading="lazy"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Amenities}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Slider>
              </div>
              <div className="col-sm-4">
                <img
                  className="imagedata"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  src={Noc}
                  loading="lazy"
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                />
                <img
                  className="imagedata"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  src={Amenities}
                  style={{
                    width: "100%",
                    height: "auto",
                    paddingTop: "0.89em",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex">
          <a href="#profile-tab">
            <i
              className="bi bi-camera-reels mx-2"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => document.getElementById("profile-tab").click()}
              style={{ cursor: "pointer" }}
            ></i>
          </a>
          <a>
            <i
              className="bi bi-geo-alt mx-2"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => document.getElementById("contact-tab").click()}
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </div>

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ zIndex: "100000000000" }}
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Avenue 18
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Images
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Map
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="slider-container p-5">
                      <Slider {...settingss}>
                        <div>
                          <img
                            loading="lazy"
                            src={Noc}
                            alt="Avenue18"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>

                        <div>
                          <img
                            loading="lazy"
                            src={Amenities}
                            alt="Avenue18-map"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="p-5">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.2580379068686!2d73.08601697440645!3d33.546671444259026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfed8f1af16861%3A0x6394b0e09933c725!2sAvenue%2018%20DHA%201%20Islamabad!5e0!3m2!1sen!2s!4v1736141475136!5m2!1sen!2s"
                        width="100%"
                        height="400px"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Div  */}

        <div className="fixed-bar">
          <button type="button" class="btn btn-primary">
            Contact Us
          </button>

          <a href="+923311111049">
            <button className="col-sm-4 btn fixed-button">
              <i class="bi bi-telephone"></i> Call
            </button>
          </a>
          <a href="http://wa.me/+923311111045">
            <button className="col-sm-4 btn fixed-button">
              <i class="bi bi-whatsapp"></i>
            </button>
          </a>
        </div>

        {/* Tabs bars  */}
        <div
          ref={navRef}
          className={`mid-nav ${isFixed ? "fixed" : ""}`}
          id="midNav"
          style={{ zIndex: "10000000" }}
        >
          <div className="nav-buttons-container py-3">
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("overview")}
            >
              Overview
            </button>

            <button
              className="nav-button"
              onClick={() => handleScrollToSection("development")}
            >
              Development Updates
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("location")}
            >
              Location
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("payment")}
            >
              Payment Plans Floor Plans
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("marketed")}
            >
              Marketed By
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("developed")}
            >
              Developed By
            </button>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Overview
                    </h3>
                    <img
                      loading="lazy"
                      src={require("../img/arrow.png")}
                      alt=""
                    />

                    {/* <div>
                      <p style={{ paddingTop: "1em" }}>
                        {isExpandedd ? fullText : shortenedText}
                      </p>
                      <button
                        onClick={() => setIsExpandedd(!isExpandedd)}
                        style={{
                          color: "#4db749",
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                      >
                        {isExpandedd ? "Show Less" : "Show More"}
                      </button>
                    </div> */}

                    <li class="d-flex justify-content-between align-items-center p-3 ">
                      {/* Left Section: Icon + Text */}
                      <div className="d-flex align-items-left flex-column">
                        <i class="bi bi-geo-alt"></i>
                        <span style={{ fontSize: "14px" }}>Location</span>
                        <div class="fw-bold">DHA 1 Islamabad</div>
                      </div>

                      {/* Spacer to push content to the right */}
                      <div className="flex-grow-1 flex-grow-sm-2"></div>

                      {/* Right Section: Price + Dropdown Icon */}
                      <div className="d-flex align-items-left flex-column">
                        <i className="bi bi-person"></i>
                        <span style={{ fontSize: "14px" }}>Developer</span>
                        <div className="fw-bold ">Makkaan Development</div>
                      </div>
                    </li>

                    <div class="accordion mt-5" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            className="accordion-button d-flex align-items-center w-100 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            style={{
                              backgroundColor: "",
                              paddingTop: "40px",
                              paddingBottom: "40px",

                              border: "none",
                            }}
                          >
                            {/* Left Section: Icon + Text */}
                            <div className="d-flex align-items-center">
                              <span className="fw-bold">Shops</span>
                            </div>

                            {/* Spacer to push content to the right */}
                            <div className="flex-grow-1"></div>

                            {/* Right Section: Price + Dropdown Icon */}
                            <div className="d-flex align-items-center">
                              <span
                                className="text-muted me-1"
                                style={{ fontSize: "12px" }}
                              >
                                PKR
                              </span>
                              <span className="fw-bold me-1">
                                55 Thousand to
                                <span className="mobile-break">
                                  <br />
                                </span>{" "}
                                85 Thousand
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <li className=" d-flex justify-content-between align-items-center p-3 shadow-sm rounded">
                              <div className="d-flex flex-column">
                                <div
                                  className="fw-bold"
                                  style={{ color: "#4db749" }}
                                >
                                  Lower Ground Shops
                                </div>
                                <div className="d-flex align-items-center text-muted">
                                  <span>Area Size: Per sq.ft</span>
                                </div>
                              </div>
                              <div className="text-end fw-bold  ">
                                <span
                                  className="text-muted me-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  PKR
                                </span>
                                55 Thousand
                              </div>
                            </li>

                            <li className="d-flex justify-content-between align-items-center p-3 shadow-sm rounded mt-4 mb-4">
                              <div className="d-flex flex-column">
                                <div
                                  className="fw-bold"
                                  style={{ color: "#4db749" }}
                                >
                                  Ground Floor Shops
                                </div>
                                <div className="d-flex align-items-center text-muted">
                                  <span>Area Size: Per sq.ft</span>
                                </div>
                              </div>
                              <div className="text-end fw-bold ">
                                <span
                                  className="text-muted me-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  PKR
                                </span>
                                85 Thousand
                              </div>
                            </li>
                            <li className="d-flex justify-content-between align-items-center p-3 shadow-sm rounded">
                              <div className="d-flex flex-column">
                                <div
                                  className="fw-bold"
                                  style={{ color: "#4db749" }}
                                >
                                  First Floor Shops
                                </div>
                                <div className="d-flex align-items-center text-muted">
                                  <span>Area Size: Per sq.ft</span>
                                </div>
                              </div>
                              <div className="text-end fw-bold  ">
                                <span
                                  className="text-muted me-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  PKR
                                </span>
                                60 Thousand
                              </div>
                            </li>
                            <li className="d-flex justify-content-between align-items-center p-3 mt-4 mb-4  shadow-sm rounded">
                              <div className="d-flex flex-column">
                                <div
                                  className="fw-bold"
                                  style={{ color: "#4db749" }}
                                >
                                  2nd Floor Shops
                                </div>
                                <div className="d-flex align-items-center text-muted">
                                  <span>Area Size:120 to 235 sq.ft</span>
                                </div>
                              </div>
                              <div className="text-end fw-bold  ">
                                <span
                                  className="text-muted me-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  PKR
                                </span>
                                55 lakh to 85 lakh
                              </div>
                            </li>
                            <li className="d-flex justify-content-between align-items-center p-3   shadow-sm rounded">
                              <div className="d-flex flex-column">
                                <div
                                  className="fw-bold"
                                  style={{ color: "#4db749" }}
                                >
                                  3rd Floor Shops
                                </div>
                                <div className="d-flex align-items-center text-muted">
                                  <span>Area Size:120 to 235 sq.ft</span>
                                </div>
                              </div>
                              <div className="text-end fw-bold  ">
                                <span
                                  className="text-muted me-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  PKR
                                </span>
                                65 lakh to 90 lakh
                              </div>
                            </li>
                          </div>
                        </div>

                        <hr
                          className="my-0 mx-3"
                          style={{ borderTop: "3px solidrgb(95, 99, 102)" }}
                        />

                        <div class="accordion-item pt-2">
                          <h2 class="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button  d-flex align-items-center w-100 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-controls="collapseTwo"
                              style={{
                                paddingTop: "40px",
                                paddingBottom: "40px",

                                border: "none",
                              }}
                            >
                              {/* Left Section: Icon + Text */}
                              <div className="d-flex align-items-center">
                                <span className="fw-bold">Offices</span>
                              </div>

                              {/* Spacer to push content to the right */}
                              <div className="flex-grow-1"></div>

                              {/* Right Section: Price + Dropdown Icon */}
                              <div className="d-flex align-items-center">
                                <span
                                  className="text-muted me-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  PKR
                                </span>
                                <span className="fw-bold me-1">
                                  33 Thousand
                                </span>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <li className=" d-flex justify-content-between align-items-center p-3 shadow-sm rounded">
                                <div className="d-flex flex-column">
                                  <div
                                    className="fw-bold"
                                    style={{ color: "#4db749" }}
                                  >
                                    4th & 5th Floor Offices
                                  </div>
                                  <div className="d-flex align-items-center text-muted">
                                    <span>Area Size: Per sq.ft</span>
                                  </div>
                                </div>
                                <div className="text-end fw-bold  ">
                                  <span
                                    className="text-muted me-1"
                                    style={{ fontSize: "12px" }}
                                  >
                                    PKR
                                  </span>{" "}
                                  33 Thousand
                                </div>
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className=" col-lg-4 hide-on-mobile"
              // style={{
              //   position: isSticky ? "fixed" : "relative",
              //   top: isSticky ? "150px" : "auto",
              //   right: isSticky ? "50px" : "auto",
              //   zIndex: "9999",
              //   transition: "all 0.3s ease-in-out",
              // }}
            >
              <div
                style={{
                  backgroundColor: "#3f4040",
                  padding: "2em",
                  paddingTop: "1em",
                  borderRadius: "10px",
                }}
              >
                <h4 className="text-center text-white">MAKE AN ENQUIRY</h4>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="fullName"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="city"
                          className="form-control rounded-1"
                          id="city"
                          placeholder="city"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-1">
                        <textarea
                          className="form-control rounded-1"
                          placeholder="Message"
                          id="yourMessage"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn bg_makkan text-white w-100 mt-2"
                        style={{ backgroundColor: "#4DB749" }}
                        onClick={submit}
                      >
                        Submit
                      </button>
                    </div>

                    <div className="col-lg-12 pt-2">
                      <div
                        className="d-flex justify-content-center "
                        style={{ gap: "12px" }}
                      >
                        <a href="tel:03311111049">
                          <button className="btn btn-danger w-100 py-1 px-5 d-flex align-items-center justify-content-center">
                            <MdCall color="#fff" /> Call
                          </button>
                        </a>
                        <a href="mailto:makkaan14@gmail.com">
                          <button className="btn btn-primary  py-1 px-5">
                            <MdEmail color="#fff" /> Email
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Avenue 18
                    </h3>
                    <img
                      loading="lazy"
                      src={require("../img/arrow.png")}
                      alt=""
                    />

                    <h1
                      style={{
                        color: "#4db749",
                      }}
                    >
                      Welcome to Avenue 18 DHA 1 – The Future of Business in
                      Islamabad
                    </h1>
                    <br />

                    <p>
                      Avenue 18 DHA 1 is a signature commercial project by
                      Makkaan Developments. This premier commercial hub in
                      Islamabad offers a unique blend of modern architecture,
                      strategic location, and potential business opportunities.
                      Avenue 18 caters to diverse business needs with
                      meticulously designed commercial spaces, corporate
                      offices, and recreational areas.
                      <br />
                      <br />
                      <h4 style={{ color: "#4db749" }}>
                        Available Spaces at Avenue 18 DHA 1 Islamabad
                      </h4>
                      <br />
                      <p>
                        The following spaces are available for businesses and
                        investors to consider
                      </p>
                      <ol class="custom-list">
                        <li>
                          {" "}
                          <b>Retail Spaces:</b>Explore a variety of sizes to
                          suit your business needs.
                        </li>
                        <li>
                          {" "}
                          <b>Corporate Offices</b>Find the perfect innovative
                          workspace for your team, with customizable options
                          available.
                        </li>
                        <li>
                          {" "}
                          <b>Food Court Units</b>Join a vibrant culinary scene
                          and attract a diverse clientele.
                        </li>
                      </ol>
                    </p>
                    {isExpanded && (
                      <p>
                        <h4 style={{ color: "#4db749" }}>
                          Why invest in Avenue 18?
                        </h4>
                        Before exploring this premium commercial project, you
                        must know why it is a goldmine for businesses and
                        investors.
                        <ol>
                          <h5 style={{ color: "#4db749" }}>
                            <li>
                              <b>Prime Location</b>
                            </li>
                            <br />
                          </h5>
                          <p>
                            Avenue 18 DHA 1 Islamabad ensures easy connectivity
                            to key areas of the city. Its strategic location
                            attracts high foot traffic, making it the ultimate
                            destination for growing business, retail, and
                            vigorous recreational activities.
                          </p>
                          <h5 style={{ color: "#4db749" }}>
                            <li>
                              <b>Modern Architecture and Contemporary Design</b>
                            </li>
                          </h5>
                          <br />
                          <p>
                            The sleek architecture of Avenue 18 reflects
                            sophistication and innovation. With a focus on
                            user-experience, its well-ventilated wide corridors
                            and premium finishes make it a standout destination
                            for businesses and visitors.
                          </p>

                          <h5 style={{ color: "#4db749" }}>
                            <li>
                              <b>High Potential for Foot Traffic</b>
                            </li>
                            <br />
                          </h5>
                          <p>
                            Avenue 18 offers vibrant shopping, dining, and
                            corporate spaces for consistent visitor engagement.
                            It is designed to attract families, professionals,
                            and shoppers, making it a hotspot for growth. The
                            potential visitors are from DHA Phase 1, DHA Phase
                            2, Bahria Town, and nearby housing societies in
                            Islamabad.
                          </p>

                          <h5 style={{ color: "#4db749" }}>
                            <li>
                              <b>Versatile Spaces for Every Need</b>
                            </li>
                            <br />
                          </h5>
                          <p>
                            From retail shops and food courts to corporate
                            offices, Avenue 18 caters to diverse business needs.
                            Whether establishing a retail presence or seeking
                            the perfect workspace, Avenue 18 has you covered.
                          </p>

                          <h5 style={{ color: "#4db749" }}>
                            <li>
                              <b>State-of-the-Art Amenities</b>
                            </li>
                            <br />
                          </h5>
                          <p>
                            With a customer-centric approach, this premium
                            project in DHA phase 1 offers world-class amenities,
                            including
                          </p>
                          <ol class="custom-list">
                            <li>
                              Wide 6-foot corridors for smooth navigation and
                              exceptional experience
                            </li>
                            <li>High-speed elevators and ample parking</li>
                            <li>Advanced security systems</li>
                            <li>
                              Modern facilities tailored to retail, dining, and
                              corporate environments
                            </li>
                          </ol>
                        </ol>
                        <h4 style={{ color: "#4db749" }}>
                          Explore Avenue 18: Floor-by-Floor Overview
                        </h4>
                        <p>Here is the floor plan of Avenue 18.</p>
                        <h5 style={{ color: "#4db749" }}>
                          Lower Ground Floor – Ideal for Retail Businesses
                        </h5>
                        <p>
                          The lower ground floor is reserved for commercial
                          shops, offering businesses an ideal platform to
                          establish a retail presence.
                        </p>
                        <ol class="custom-list">
                          <li>
                            <b>Shop Sizes:</b> The shop sizes vary from 200 to
                            255 sq. ft. to accommodate different business needs.
                          </li>
                          <li>
                            <b>Features:</b>A well-planned 6-foot-wide corridor
                            for smooth customer flow and convenient entrances.
                          </li>
                          <li>
                            <b>Availability:</b>A mix of sold-out and available
                            spaces.
                          </li>
                          <li>
                            <b>Why Choose This Floor:</b> High visibility and
                            accessibility make it perfect for retail brands
                            looking to attract foot traffic.
                          </li>
                        </ol>
                        <h5 style={{ color: "#4db749" }}>
                          Ground Floor – Premium Commercial Spaces
                        </h5>
                        <p>
                          The ground floor of Avenue 18 DHA 1 offers premium
                          shops.
                        </p>
                        <ol class="custom-list">
                          <li>
                            <b>Shop Sizes:</b> 200 to 255 sq. ft.
                          </li>
                          <li>
                            <b>Strategic Positioning:</b> Prime frontage and
                            easy accessibility ensure maximum visibility.
                          </li>
                          <li>
                            <b>Wide Corridors:</b> A welcoming atmosphere for
                            retailers and customers alike.
                          </li>
                          <li>
                            <b>Why Choose This Floor:</b> It offers excellent
                            exposure and high traffic, which is ideal for
                            prestigious brands.
                          </li>
                        </ol>
                        <h5 style={{ color: "#4db749" }}>
                          First Floor – Versatile Commercial and Retail Spaces
                        </h5>
                        <p>
                          This floor balances functionality and variety, making
                          it perfect for retailers.
                        </p>
                        <ol class="custom-list">
                          <li>
                            <b>Shop Sizes:</b> 140 to 225 sq. ft
                          </li>
                          <li>
                            <b>Features:</b> A 20-foot-wide central atrium
                            creates an open ambiance. There are 6-foot-wide
                            corridors to ensure convenience.
                          </li>

                          <li>
                            <b>Why Choose This Floor:</b> The well-thought-out
                            layout enhances customer experiences, ensuring
                            steady footfall.
                          </li>
                        </ol>
                        <h5 style={{ color: "#4db749" }}>
                          Second Floor – A Commercial Hub
                        </h5>
                        <p>
                          This floor at Avenue 18 is designed to facilitate
                          retailers and commercial activities
                        </p>
                        <ol class="custom-list">
                          <li>
                            <b>Purpose:</b> Flexible spaces perfect for
                            expanding businesses.
                          </li>
                          <li>
                            <b>Why Choose This Floor:</b> Accessibility and
                            modern layouts create an inviting environment for
                            retailers and customers.
                          </li>
                        </ol>
                        <h5 style={{ color: "#4db749" }}>
                          Third Floor – The Ultimate Food Court
                        </h5>
                        <p>
                          The food court attracts shoppers and visitors with its
                          irresistible food aromas.
                        </p>
                        <ol class="custom-list">
                          <li>
                            <b>Dining Spaces:</b> Multiple food outlets with
                            ample seating and a cozy ambiance.
                          </li>
                          <li>
                            <b>Modern Amenities:</b> Ventilation, advanced
                            kitchen facilities, and spacious seating areas.
                          </li>
                          <li>
                            <b>Why Choose This Floor:</b> This floor is a
                            perfect fit for food entrepreneurs.
                          </li>
                        </ol>
                        <h3>Don't Miss Out – Secure Your Spot at Avenue 18!</h3>
                        <p>
                          Opportunities like Avenue 18 don't come around often.
                          With its prime location, modern design, and high
                          footfall potential, spaces are filling up fast. Call
                          us today or visit{" "}
                          <a href="https://www.makkaan.com/" target="_blank">
                            {" "}
                            Makkaan.com
                          </a>{" "}
                          to learn about Avenue 18 payment plans and booking
                          details. Don't let this chance slip away – the future
                          of your business starts here!
                        </p>
                      </p>
                    )}
                    <a
                      onClick={toggleReadMore}
                      style={{ color: "green", cursor: "pointer" }}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="development">
          <div className="row">
            <div className="col-lg-12">
              <div className="read-more-container">
                <h3 style={{ color: "#4db749", fontWeight: "bold" }}>
                  Development Updates for Avenue 18
                </h3>
                <img
                  loading="lazy"
                  src={require("../img/arrow.png")}
                  alt="Arrow icon"
                />

                <div className="row">
                  <div className="col-12">
                    <div className="timeline">
                      {stages.map((stage, index) => (
                        <div key={stage.id} className="timeline-stage">
                          <div
                            className={`circle ${
                              activeStage === stage.id ? "active" : ""
                            }`}
                            onClick={() => setActiveStage(stage.id)}
                          >
                            {stage.label}
                          </div>
                          {index < stages.length - 1 && (
                            <div className="line"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="media-container">
                      {stages.map((stage) => (
                        <div
                          key={stage.id}
                          className="media-content-wrapper"
                          style={{
                            display:
                              activeStage === stage.id ? "block" : "none",
                          }}
                        >
                          <div className="media-label">{stage.label}</div>
                          <img
                            loading="lazy"
                            key={stage.id}
                            src={stage.media}
                            alt="Avenue18-DHA"
                            onClick={() => {
                              setModalImage(stage.media);
                              setShowModal(true);
                            }}
                            style={{
                              width: "100%", // Full width on mobile
                              maxWidth: "500px", // Restricts max size on desktop
                              height: "auto", // Keeps aspect ratio
                              margin: "15px",
                              cursor: "pointer",
                              objectFit: "cover",
                              borderRadius: "25px",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Modal */}
                  {showModal && (
                    <div
                      className="modal-backdrop"
                      onClick={() => setShowModal(false)}
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        zIndex: 999999999,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        loading="lazy"
                        src={modalImage}
                        alt="Selected stage"
                        style={{
                          width: "100%", // Full width on mobile
                          maxWidth: "90%", // Restricts size on desktop
                          height: "auto",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalImage && (
                <img
                  loading="lazy"
                  src={modalImage}
                  alt="Avenue18-development"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </Modal.Body>
          </Modal>
        </div>

        <div className="container mt-5" id="location">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Location
                    </h3>
                    <img
                      loading="lazy"
                      src={require("../img/arrow.png")}
                      alt=""
                    />
                    <div className="row mt-3">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.2580379069223!2d73.08601697520385!3d33.54667144425763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfed8f1af16861%3A0x6394b0e09933c725!2sAvenue%2018%20DHA%201%20Islamabad!5e0!3m2!1sen!2s!4v1737807465604!5m2!1sen!2s"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Map"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="payment">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Payment Floor plans For Avenue 18 DHA 1 Islamabad
                    </h3>
                    <img
                      loading="lazy"
                      src={require("../img/arrow.png")}
                      alt=""
                    />

                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4">
                        <div className="card" style={{ width: "100%" }}>
                          <div className="row">
                            <a
                              href={paymentplan}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                loading="lazy"
                                src={payment}
                                className="card-img-top"
                                alt="Avenue18-paymentplan"
                                style={{ padding: "10px", borderRadius: "5px" }}
                              />
                            </a>
                            <div className="card-body">
                              <p className="card-text">
                                Avenue 18 Payment Plan
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4">
                        <div className="card" style={{ width: "100%" }}>
                          <a
                            href={floorplan}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              loading="lazy"
                              src={floor}
                              className="card-img-top"
                              alt="Avenue18-floorplan"
                              style={{ padding: "10px", borderRadius: "5px" }}
                            />
                          </a>
                          <div className="card-body">
                            <p className="card-text">Avenue 18 Floor plans</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-12">
                <div className="read-more-container">
                  <h3
                    style={{
                      color: "#4db749",
                      fontWeight: "bold",
                    }}
                  >
                    FAQ's
                  </h3>
                  <img
                    loading="lazy"
                    src={require("../img/arrow.png")}
                    alt=""
                  />

                  <div class="accordion mt-3" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Q1: What sizes of commercial shops are available at
                          Avenue 18?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                      >
                        <div className="accordion-body lh-base">
                          A: Shop sizes range from 140 sq. ft. to 255 sq. ft.,
                          catering to various business needs.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Q2: Where is Avenue 18 located?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                      >
                        <div className="accordion-body lh-base">
                          A: Avenue 18 is located in DHA Phase 1, Islamabad.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Q3: What amenities are available at Avenue 18 DHA 1?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                      >
                        <div className="accordion-body lh-base">
                          A: Avenue 18 offers high-speed elevators, advanced
                          security systems, ample parking, wide corridors, and
                          modern facilities tailored to retail, dining, and
                          corporate needs.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Q4: Is Avenue 18 suitable for startups?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                      >
                        <div className="accordion-body lh-base">
                          A: With flexible payment plans and customizable
                          corporate spaces, Avenue 18 is perfect for startups.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Q5: How do I book a space at Avenue 18 DHA 1
                          Islamabad?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                      >
                        <div className="accordion-body lh-base">
                          A: Contact us now to secure your spot in the capital's
                          most sought-after commercial hub.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-3 mt-3" id="marketed">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      loading="lazy"
                      src={makkaanLogo}
                      className="img-fluid rounded-start"
                      alt="Makkaan-logo"
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Marketed By</h3>
                      <h5 className="card-title">Makkaan.com</h5>
                      <p>
                        Makkaan.com is a trustworthy and responsible platform
                        that aims to provide you with all the Residential,
                        Commercial, and Investment details under the same roof.
                      </p>
                      {isExpanded && (
                        <p>
                          The only podium that offers reliable services to
                          investors, dealers, societies, and end-users of the
                          real estate world throughout the country. We use an
                          all-inclusive marketing strategy that covers all
                          aspects of projects and helps investors at every stage
                          of the process with complete transparency.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      loading="lazy"
                      src={developerlogo}
                      className="img-fluid rounded-start"
                      alt="Avenue18-logo"
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Developed By</h3>
                      <h5 className="card-title">Makkaan Development</h5>
                      <p>
                        Makkaan development's first-ever commercial project,
                        located at the heart of the city, sets a new standard
                        for business spaces with its prime location, modern
                        architecture, customizable layouts, state-of-the-art
                        facilities, and eco-friendly construction practices.
                      </p>
                      {isExpanded && (
                        <p>
                          Backed by a dedicated team of professionals, we ensure
                          on-time delivery, exceptional quality, and a
                          customer-centric approach to meet the evolving
                          needs of businesses.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Avenue18;
